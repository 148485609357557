import { BsThreeDots } from "react-icons/bs";
import { FaCloudDownloadAlt, FaFileExcel } from "react-icons/fa"; // Added FaFileExcel icon
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment";
import InvoiceNumberComponent from "../Format";
import * as XLSX from "xlsx"; // Import xlsx for Excel

const Dashboard = (props) => {
  props.setShow(true);
  const user = props.user;
  const [filter, setFilter] = useState({
    filter: "",
    asc: "no",
    fromDate: "",
    toDate: "",
  });
  const userToken = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState([]);

  const handleDelete = (invoiceId) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_HOST}invoice/${invoiceId}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Invoice Deleted Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleView = (invoiceId) => {
    const url = `/invoice/${invoiceId}/view`;
    window.open(url, "_blank");
  };

  const handleAddInvoice = () => {
    navigate("/create-invoice/new");
  };

  const handleDownloadInvoice = async (invoiceId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_HOST}invoice/${invoiceId}`,
        config
      );
      const invoiceData = {
        ...response.data.invoice,
        ...response.data.customerDetails,
      };

      const invoiceArray = [];

      // Flatten main invoice data
      Object.entries(invoiceData).forEach(([key, value]) => {
        if (key !== "productDesc") {
          // Skip productDesc for now
          invoiceArray.push({ Field: key, Value: value });
        }
      });

      // Handle productDesc array
      if (invoiceData.productDesc && Array.isArray(invoiceData.productDesc)) {
        invoiceData.productDesc.forEach((product, index) => {
          invoiceArray.push({
            Field: `Product ${index + 1} - Name`,
            Value: product.name,
          });
          invoiceArray.push({
            Field: `Product ${index + 1} - Quantity`,
            Value: product.qty,
          });
          invoiceArray.push({
            Field: `Product ${index + 1} - Price`,
            Value: product.price,
          });
          invoiceArray.push({
            Field: `Product ${index + 1} - UOM`,
            Value: product.uom,
          });
          invoiceArray.push({
            Field: `Product ${index + 1} - HSN`,
            Value: product.hsn,
          });
        });
      }

      const worksheet = XLSX.utils.json_to_sheet(invoiceArray); // Convert array to worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Invoice");

      // Creating Excel file and triggering download
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", `Invoice_${invoiceData.billNumber}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading invoice:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to Download Invoice",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  useEffect(() => {
    handleFilter();
  }, []);

  const handleFilter = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_HOST}filterinvoices?fromDate=${filter.fromDate}&toDate=${filter.toDate}&filter=${filter.filter}&asc=${filter.asc}`,
        config
      );
      setInvoices(response.data.invoices);
    } catch (error) {
      setInvoices([]);
    }
  };

  return (
    <div className="container-right">
      <div className="container mt-5 mt-0 d-flex flex-column">
        <div className="filter">
          <select
            onChange={(e) => setFilter({ ...filter, filter: e.target.value })}
          >
            <option value={"todays"}>Today's</option>
            <option value={"Last3months"}>Last 3 months</option>
            <option value={"Last6months"}>Last 6 months</option>
            <option value={"Last12months"}>Last 12 months</option>
            <option value={"Draft"}>Draft</option>
          </select>
          <select
            onChange={(e) => setFilter({ ...filter, asc: e.target.value })}
          >
            <option value={"yes"}>Ascending</option>
            <option value={"no"}>Descending</option>
          </select>
          <br />
          <div>
            <input
              type="date"
              placeholder="Select Start Date"
              value={filter.fromDate}
              onChange={(e) =>
                setFilter({ ...filter, fromDate: e.target.value })
              }
            />
            <input
              type="date"
              placeholder="Select End Date"
              value={filter.toDate}
              onChange={(e) => setFilter({ ...filter, toDate: e.target.value })}
            />
            <button type="button" onClick={handleFilter}>
              Search
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button className="btn btn-dark new" onClick={handleAddInvoice}>
            + New
          </button>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">
                <td>
                  <input type="checkbox" />
                </td>
              </th>
              <th scope="col">Date</th>
              <th scope="col">Invoice#</th>
              <th scope="col">Organisation Name</th>
              <th scope="col">Status</th>
              <th scope="col">Amount</th>
              <th scope="col">Payment Status</th>
              <th scope="col">Manage</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice, index) => (
              <tr
                className={
                  index % 2 === 0 ? "bg-light" : "bg-secondary text-black"
                }
              >
                <td>
                  <input type="checkbox" />
                </td>
                <td>{moment(invoice.createdDate).format("DD-MM-YYYY")}</td>
                <td>
                  <InvoiceNumberComponent
                    page={invoice?.invType === "Proforma" ? "P_INV" : "INV"}
                    billNumber={invoice.billNumber}
                  />
                </td>
                <td>{invoice.orgName}</td>
                <td>{invoice.invoiceStatus}</td>
                <td>₹ {invoice.totalINR.toFixed(2)}</td>
                <td>{invoice.paymentStatus}</td>
                <td>
                  <DropdownButton
                    id="dropdown-item-button btn-dark"
                    variant="dark"
                    title={<BsThreeDots size={26} />}
                  >
                    <Dropdown.Item
                      as="button"
                      onClick={() => handleView(invoice._id)}
                    >
                      View
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() => navigate(`/invoice/edit/${invoice._id}`)}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() => handleDelete(invoice._id)}
                    >
                      Delete
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() => handleDownloadInvoice(invoice._id)}
                    >
                      Download Excel <FaFileExcel />
                    </Dropdown.Item>
                  </DropdownButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Dashboard;
