import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const InvoiceForm = (prop) => {
    prop.setShow(true);
    const [key, setKey] = useState('personal');
    const propId = useParams().propId;
    const userToken = localStorage.getItem("userToken");
    const [taxType, setTaxType] = useState('');
    const navigate = useNavigate();
    const [vendors, setVendors] = useState([]);
    const [logos, setlogos] = useState([]);
    const [newProduct, setNewProduct] = useState({ name: '', qty: 0, price: 0, uom: '', hsn: "" });
    const [selectedVendor, setselectedVendor] = useState({});
    const [formData, setFormData] = useState({
        logoURL: '',
        productType: 'goods',
        eWayBillNo: '',
        transNo: '',
        transId: '',
        invType: 'Proforma',
        taxInvoiceNumber: '',
        invoiceStatus: 'draft',
        refPONumber: '',
        refQuotationNumber: '',
        refQuotationDate: '',
        site: '',
        productDesc: [],
        discount: 0,
        taxRate: 0,
        shipOrHandCharge: 0,
        revCharge: 'no',
        igst: 18,
        cgst: 9,
        sgst: 9,
        shipAddress: '',
        paymentStatus:'pending'
    });


    useEffect(() => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_HOST}proposal/${propId}`,
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        };

        axios.request(config)
            .then((response) => {

                const proposal = response.data.proposal;
                setFormData({
                    ...formData,
                    logoURL: proposal?.logoURL,
                    vendorId: proposal?.vendorId,
                    orgName: proposal?.orgName,
                    transNo: proposal?.transNo,
                    transId: proposal?.transId,
                    customAddress: proposal?.customAddress,
                    customName: proposal?.customName,
                    customerId: proposal?._id,
                    refQuotationNumber: proposal?.billNumber,
                    refQuotationDate: proposal?.refQuotationDate,
                    gstin: proposal?.gstin,
                    productDesc: proposal?.productDesc,
                    discount: proposal?.discount,
                    taxRate: proposal?.taxRate,
                    shipOrHandCharge: proposal?.shipOrHandCharge,
                    refPONumber: proposal?.refPONumber,
                    shipAddress: proposal?.shipAddress
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])





    const loadVendors = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_HOST}customersmine`,
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        };

        axios.request(config)
            .then((response) => {
                setVendors(response.data.customers);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const loadLogos = () => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_HOST}alllogos`,
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        };

        axios.request(config)
            .then((response) => {
                setlogos(response.data.logos);
            })
            .catch((error) => {
                console.log(error);
            });
    }



    useEffect(() => {
        loadLogos();
        loadVendors();
    }, []);

    const handleAddProduct = () => {
        const { name, qty, price, productType, uom, hsn } = newProduct;
        const updatedProducts = [...formData.productDesc, { name, qty, hsn, price, productType, uom }];
        setFormData({
            ...formData,
            productDesc: updatedProducts,
        });
        setNewProduct({ name: '', qty: 0, price: 0, productType: '', uom: '', hsn: "" });
    };

    const handleDeleteProduct = (index) => {
        const updatedProducts = formData.productDesc.filter((product, i) => i !== index);
        setFormData({ productDesc: updatedProducts });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleInputChange2 = (e) => {
        const { name, value } = e.target;
        setNewProduct((prevProduct) => ({ ...prevProduct, [name]: value }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = JSON.stringify(formData
        );


        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_HOST}invoice`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            },
            data: data
        }
        axios.request(config)
            .then((response) => {
                if (response?.data.status) {
                    navigate("/invoices");
                    Swal.fire({
                        icon: "success",
                        title: "Invoice Created Successfully",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }

            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "warning",
                    title: error.response?.data?.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
            });

    };
    const calculateFinalAmount = () => {

        return formData.productDesc.reduce((total, product) => total + (product.price * product.qty), 0);
    };
    const calculateGoodsAmount = () => {
        const goodsTotal = formData.productDesc
            .filter(product => product.productType === 'Goods')
            .reduce((total, product) => total + (product.price * product.qty), 0);
        return goodsTotal;
    };

    const handleVendorSelected = (s) => {
        setFormData({
            ...formData,
            customName: s?.nameOfContact,
            customAddress: s?.address,
            customerId: s?._id,
            orgName: s?.orgName,
            vendorMobile: s?.phone,
            gstin:s?.gstNo,
            vendorPAN: s?.pan,
            vendorState: s?.state,
            vendorCity: s?.city

        })
    }


    return (
        <div className="container-right">
            <div className='container mt-5 mb-5'>
                <h2 className='font-weight-bolder mt-4 mb-4'>  New Invoice</h2>
                <Form onSubmit={handleSubmit}>
                    <div className='create-invoice'>
                        <div className='label-input'>
                            <label>Organisation Name*</label>
                            <Form.Control
                                className='border mb-3'
                                as="select"
                                placeholder="Vendor Name"
                                name="vendorIds"
                                onChange={(e) => {
                                    setselectedVendor(JSON.parse(e.target.value));
                                    handleVendorSelected(JSON.parse(e.target.value));

                                }}
                            >

                                <option></option>

                                {vendors.map((v) => (
                                    <option key={v._id} value={JSON.stringify(v)}>{v.orgName}</option>
                                ))}

                            </Form.Control>



                        </div>
                        {calculateGoodsAmount() >= 50000 &&
                            <div className='label-input'>
                                <Form.Group controlId="eWayBillNo">
                                    <Form.Label>E Way Bill No* </Form.Label>
                                    <Form.Control
                                        className='border mb-3'
                                        type="text"
                                        placeholder="Enter E Way Bill Number"
                                        name="eWayBillNo"
                                        value={formData.eWayBillNo}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </div>
                        }
                    </div>

                    <div className='customer-invoice-tabs'>

                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="personal" title="Item Table">
                                <div className='biiling-inshights product'>
                                    <Form.Group controlId="productDesc">

                                        <div className='mb-4'>
                                            <div className="row">
                                                <div className="col-sm product">
                                                    <label>
                                                        Product Name:
                                                        <textarea rows={1} type="text" className="form-control" name="name" value={newProduct.name} onChange={handleInputChange2} />
                                                    </label>
                                                </div>
                                                <div className="col-sm product">
                                                    <label>
                                                        Product Type:
                                                        <select name="productType" value={newProduct.productType} onChange={handleInputChange2} >
                                                            <option>Select</option>
                                                            <option>Goods</option>
                                                            <option>Services</option>
                                                            <option>Goods & Services</option>
                                                        </select>
                                                    </label>
                                                </div>
                                                <div className="col-sm product">
                                                    <label>
                                                        UOM:
                                                        <select className="form-control" name="uom" value={newProduct.uom} onChange={handleInputChange2}>
                                                            <option></option>
                                                            <option value={'Nos'}>Nos</option>
                                                            <option value={'Litre'}>Litre</option>
                                                            <option value={'Kgs'}>Kgs</option>
                                                            <option value={'Lots'}>Lots</option>

                                                        </select>
                                                    </label>
                                                </div>
                                                <div className="col-sm product">
                                                    <label>
                                                        HSN Code:
                                                        <input type="text" className="form-control" name="hsn" value={newProduct.hsn} onChange={handleInputChange2} />
                                                    </label>
                                                </div>
                                                <div className="col-sm product">
                                                    <label>
                                                        qty:
                                                        <input type="number" className="form-control" name="qty" value={newProduct.qty} onChange={handleInputChange2} />
                                                    </label>
                                                </div>
                                                <div className="col-sm product">
                                                    <label>
                                                        Price:
                                                        <input type="number" className="form-control" name="price" value={newProduct.price} onChange={handleInputChange2} />
                                                    </label>
                                                </div>
                                                <div className="col-sm-auto align-self-end">
                                                    <button type='button' className="btn btn-primary" id="add" onClick={handleAddProduct}>+</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='added-items'>
                                            <h6>Added Items</h6>
                                            <div className='mb-4'>
                                                <div className="row">
                                                    <div className="col-sm added">
                                                        {formData?.productDesc?.map((product, index) => (
                                                            <>
                                                                <label>
                                                                    Product Name:
                                                                    <textarea rows={1} type="text" className="form-control" value={product.name} disabled />
                                                                </label>
                                                                <label>
                                                                    Product Type:
                                                                    <input type="text" className="form-control" value={product.productType} disabled />
                                                                </label>
                                                                <label>
                                                                    UOM:
                                                                    <input type="text" className="form-control" value={product.uom} disabled />
                                                                </label>
                                                                <label>
                                                                    HSN Code:
                                                                    <input type="text" className="form-control" value={product.hsn} disabled />
                                                                </label>

                                                                <label>
                                                                    Qty:
                                                                    <input type="number" className="form-control" value={product.qty} disabled />
                                                                </label>

                                                                <label>
                                                                    Price:
                                                                    <input type="number" className="form-control" value={product.price} disabled />
                                                                </label>

                                                                <label>
                                                                    Total Amount:
                                                                    <input type="number" className="form-control" value={product.price * product.qty} disabled />
                                                                </label>

                                                                <button type='button' className="btn btn-danger btn-sm ml-2" onClick={() => handleDeleteProduct(index)}>
                                                                    -
                                                                </button>
                                                                <br />
                                                            </>
                                                        ))}


                                                        <div className="mt-3">
                                                            <label>
                                                                Final Amount:
                                                                <input type="number" className="form-control" value={calculateFinalAmount()} disabled />
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </Form.Group>
                                </div>
                            </Tab>




                            <Tab eventKey="invoicing" title="Invoice">
                                <div className='biiling-inshights product'>
                                    <Form.Group controlId="invType">
                                        <Form.Label>Invoice Type</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            as="select"

                                            name="invType"
                                            value={formData.invType}
                                            onChange={handleInputChange}
                                        >
                                            <option value={'Tax'}>Tax</option>
                                            <option value={'Proforma'}>Proforma</option>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="invType">
                                        <Form.Label>Logo Type</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            as="select"

                                            name="logoURL"
                                            value={formData.logoURL}
                                            onChange={handleInputChange}
                                        >
                                            {logos?.map((l) => (
                                                <option value={l.url}>{l.logoName}</option>
                                            ))}

                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="dateOfGoods">
                                        <Form.Label>Date of Goods</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="date"
                                            name="dateOfGoods"
                                            placeholder='Enter Date of Goods'
                                            value={formData.dateOfGoods}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="paymentStatus">
                                        <Form.Label>Payment Status</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            as="select"

                                            name="paymentStatus"
                                            value={formData.paymentStatus}
                                            onChange={handleInputChange}
                                        >
                                            <option value={'received'}>Received</option>
                                            <option value={'pending'}>Pending</option>
                                            <option value={'hold'}>Hold</option>

                                        </Form.Control>
                                    </Form.Group>


                                </div>

                            </Tab>
                            {calculateGoodsAmount() >= 50000 &&

                                <Tab eventKey="transport" title="Transportation Details">
                                    <div className='biiling-inshights product'>


                                        <Form.Group controlId="motorNo">
                                            <Form.Label>Transport Motor Number</Form.Label>
                                            <Form.Control
                                                className='border mb-3'
                                                type="text"
                                                placeholder='Enter Transport Motor No.'
                                                name="motorNo"
                                                value={formData.motorNo}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="challanNoTrans">
                                            <Form.Label>Challan Number</Form.Label>
                                            <Form.Control
                                                className='border mb-3'
                                                type="text"
                                                placeholder='Enter Challan Number'
                                                name="challanNoTrans"
                                                value={formData.challanNoTrans}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="transporterGST">
                                            <Form.Label>Transporter GST</Form.Label>
                                            <Form.Control
                                                className='border mb-3'
                                                type="text"
                                                name="transporterGST"
                                                placeholder='Enter Transporter GST'
                                                value={formData.transporterGST}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="transporterName">
                                            <Form.Label>Transporter Name</Form.Label>
                                            <Form.Control
                                                className='border mb-3'
                                                type="text"
                                                name="transporterName"
                                                placeholder='Enter Transporter Name'
                                                value={formData.transporterName}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>

                                    </div>

                                </Tab>
                            }

                            <Tab eventKey="tax" title="Tax & Discount">
                                <div className='biiling-inshights product'>
                                    <Form.Group controlId="discount">
                                        <Form.Label>Discount (in %)</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="number"
                                            placeholder="Enter Discount"
                                            name="discount"
                                            value={formData.discount}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    {/* Tax Rate */}
                                    <Form.Group controlId="taxRate">
                                        <Form.Label>Tax type</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            as={'select'}
                                            placeholder="Enter Tax Type"
                                            name="taxType"
                                            value={formData.taxType}
                                            onChange={handleInputChange}
                                        >
                                            <option value={``}>Select</option>
                                            <option value={'igst'}>IGST</option>
                                            <option value={'cgst'}>CGST</option>

                                        </Form.Control>
                                    </Form.Group>

                                    {/* Tax Rate */}
                                    {formData.taxType === 'igst' && (
                                        <Form.Group controlId="taxRate">
                                            <Form.Label>IGST</Form.Label>
                                            <Form.Control
                                                className='border mb-3'
                                                type="number"
                                                placeholder="Enter Tax Rate"
                                                name="taxRate"
                                                value={18}

                                            />
                                        </Form.Group>
                                    )} {formData.taxType === 'cgst' &&
                                        (
                                            <>
                                                <Form.Group controlId="taxRate">
                                                    <Form.Label>CGST</Form.Label>
                                                    <Form.Control
                                                        className='border mb-3'
                                                        type="number"
                                                        placeholder="Enter Tax Rate"
                                                        name="taxRate"
                                                        value={9}

                                                    />
                                                </Form.Group>

                                                <Form.Group controlId="taxRate">
                                                    <Form.Label>SGST</Form.Label>
                                                    <Form.Control
                                                        className='border mb-3'
                                                        type="number"
                                                        placeholder="Enter Tax Rate"
                                                        name="taxRate"
                                                        value={9}

                                                    />
                                                </Form.Group>
                                            </>
                                        )}

                                </div>

                            </Tab>

                            <Tab eventKey="transactions" title="Transaction Details">
                                <div className='biiling-inshights product'>
                                    <Form.Group controlId="transNo">
                                        <Form.Label>Transaction Number</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Transaction Number"
                                            name="transNo"
                                            value={formData.transNo}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>



                                    {/* Reference Quotation Number */}
                                    <Form.Group controlId="refQuotationNumber">
                                        <Form.Label>Reference Quotation Number</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Reference Quotation Number"
                                            name="refQuotationNumber"
                                            value={formData.refQuotationNumber}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="refQuotationNumber">
                                        <Form.Label>Reference Quotation Date</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="date"
                                            placeholder="Enter Reference Quotation Number"
                                            name="refQuotationDate"
                                            value={formData.refQuotationDate}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="refPONumber">
                                        <Form.Label>Reference PO Number</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Reference PO Number"
                                            name="refPONumber"
                                            value={formData.refPONumber}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    {/* Reference Quotation Number */}


                                    {/* GSTIN */}
                                    <Form.Group controlId="gstin">
                                        <Form.Label>GSTIN</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter GSTIN"
                                            name="gstin"
                                            value={formData.gstin}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                </div>

                            </Tab>


                            <Tab eventKey="other" title="Other">
                                <div className='biiling-inshights product'>
                                    <Form.Group controlId="productType">
                                        <Form.Label>Product Type</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            as="select"
                                            name="productType"
                                            value={formData.productType}
                                            onChange={handleInputChange}
                                        >
                                            <option value={'goods'}>Goods</option>
                                            <option value={'services'}>Services</option>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="revCharge">
                                        <Form.Label>Reverse Charge</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            as="select"
                                            name="revCharge"
                                            value={formData.revCharge}
                                            onChange={handleInputChange}
                                        >
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="shipOrHandCharge">
                                        <Form.Label>Shipping or Handling Charge</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="number"
                                            placeholder="Enter Shipping or Handling Charge"
                                            name="shipOrHandCharge"
                                            value={formData.shipOrHandCharge}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="shipAddress">
                                        <Form.Label>Shipping Address</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Shipping Address"
                                            name="shipAddress"
                                            value={formData.shipAddress}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="site">
                                        <Form.Label>Site</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Site"
                                            name="site"
                                            value={formData.site}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="remarks">
                                        <Form.Label>Remarks</Form.Label>
                                        <textarea
                                            id="textArea"
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Remarks"
                                            name="remarks"
                                            value={formData.remarks}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    {/*<Form.Group controlId="declaration">
                                        <Form.Label>Declaration</Form.Label>
                                        <Form.Control
                                            className='border mb-3'
                                            type="text"
                                            placeholder="Enter Declaration"
                                            name="declaration"
                                            value={formData.declaration}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>*/}
                                </div>

                            </Tab>
                        </Tabs>
                    </div>

                    <div className='customer-invoice-tabs'>
                        <Button variant="primary" id="draft" onClick={() => setFormData({ ...formData, invoiceStatus: "draft" })} type="submit">
                            Draft
                        </Button>

                        <Button className='m-5' id="final" onClick={() => setFormData({ ...formData, invoiceStatus: "complete" })} variant="primary" type="submit">
                            Save
                        </Button></div>


                </Form>



























            </div>
        </div>
    );
};

export default InvoiceForm;
